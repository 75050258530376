import React from "react"
import PageAnimation from "../components/animation/page-animation"
import SmoothScroll from "../helpers/smooth-scroll"
import Footer from "../components/footer"
import GlobalStyle from "../helpers/global-styles"

import { MagIcon, CookFoxIcon, DBOXLogo } from "../icons"
import RoseAssociates from "../icons/logo_rose_associates.png"
import styled from "styled-components"
import Layout from "../components/app-layout"
import { mediaMax } from "../helpers/media-queries"
import Seo from "../components/seo"

const Paragraph = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: #47341e;
`

const Title = styled.h4`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #47341e;
  margin: 0px 16px;
  margin-bottom: 20px;
`

const Category = styled.h5`
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  margin: 0px 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #47341e;
  margin-bottom: 3px;
`

const ElementWrapper = styled.div`
  padding: 80px 4vw 40px;
`

const ElementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  min-height: 300px;
  // border-bottom: 1px solid #47341e;

  ${mediaMax('tablet')} {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &:last-of-type {
    padding-bottom: 40px;
  }

  svg, img {
    max-width: 200px;
    min-width: 200px;
    max-height: 70px;
    min-height: 70px;
    align-self: center;
    background: transparent;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 430px;
  margin: 16px;
`

const ChildrenWrapper = styled.div`
  margin: 16px;
  ${mediaMax('tablet')} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const BorderLine = styled.div`
  width: 100%;
  height: 1px;
  background: #47341e;
`

const Wrapper = styled.div`
  margin-top: 24px;
`

const teamTemplate = [
  {
    category: "Development",
    title: "MAG Partners",
    paragraph:
      "MAG Partners is a woman-owned, urban real estate company with decades of experience developing impactful, iconic, large-scale projects.\n" +
      "Led by MaryAnne Gilmartin, together the MAG Partners team has successfully designed, built and operated over seven million square feet of office, residential and mixed-use projects, including over 2,000 units of housing, with a total value of over $4.5 billion.",
    icon: <MagIcon />,
    link: 'https://magpartners.com/',
  },
  {
    category: "Architecture",
    title: "Cookfox",
    paragraph:
      "COOKFOX Architects was founded in 2003 by Rick Cook and Bob Fox to pursue beautiful, innovative and sustainable design. Now led by Rick and eight partners, our core mission is to create environmentally responsible, holistic and biophilic architecture that fosters occupant well-being and a healthy urban landscape.",
    icon: <CookFoxIcon />,
    link: 'https://cookfox.com/',
  },
  {
    category: "Property Management",
    title: "Rose Associates",
    paragraph:
      "Rose Associates is a leading real estate firm specializing in luxury multifamily rental properties throughout the New York City metro area. Dedicated to the development, acquisition, and management of top-tier assets, we strive to provide an exceptional living experience for our residents every day. We are honored to have earned the Kingsley Excellence Award, ranking first in the nation for resident satisfaction for the third consecutive year!",
    icon: <img src={RoseAssociates} alt="Rose Associates" />,
    link: 'https://www.rosenyc.com/',
  },
  {
    category: "Branding & Creative",
    title: "DBOX",
    paragraph:
      `DBOX builds brands and develops immersive marketing campaigns for the fields of property development, architecture and the arts. Since being founded in 1996, DBOX has received numerous awards for their work which has been exhibited at some of the world’s most respected art institutions.`,
    icon: <DBOXLogo />,
    link: 'https://www.dbox.com/'
  },
]

const Element = ({ category, title, paragraph, children }) => {
  return (
    <>
      <ElementWrapper>
        <Category>{category}</Category>
        <Title>{title}</Title>
        <ElementContainer>
          <TextContainer>
            <Paragraph>{paragraph}</Paragraph>
          </TextContainer>
          <ChildrenWrapper>
            {children}
          </ChildrenWrapper>
        </ElementContainer>
      </ElementWrapper>
      <BorderLine />
    </>
  )
}

const Team = () => {
  return (
    <Layout pageName={'Team'}>
      <SmoothScroll>
        <PageAnimation>
          <GlobalStyle />
          <Wrapper>
            {teamTemplate.map((item, index) => {
              const { icon, link, ...rest } = item
              return (
                <Element key={index} {...rest}>
                  <a href={link} target="_blank" >{icon}</a>
                </Element>
              )
            })}
          </Wrapper>
          <Footer />
        </PageAnimation>
      </SmoothScroll>
    </Layout>
  )
}

export default Team

export const Head = () => <Seo title="Team" />
